import React from 'react'
import Layout from '../components/layout'
import Form from '../components/form'

class Index extends React.Component {
  render() {
    return(
      <React.Fragment>
        <Layout>
          <Form />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
