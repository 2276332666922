import React from "react"

class Nav extends React.Component {
  render() {
    return(
      <React.Fragment>
        <nav class="c-nav c-nav--main" />
      </React.Fragment>
    );
  }
}

export default Nav
