import React from "react"

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hostname: '',
      salt: '',
      length: '',
      passwordKey: '',
      saltLabel: 'Salt',
      windowsChosen: true,
      saltPlaceholder: 'Encryption algorithm salt',
      generatedPassword: false,
      apiResponseStatus: 0,
      alertClassString: '',
      makingPostRequest: false,
      buttonText: 'Generate password'
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateAlertClass = this.updateAlertClass.bind(this);
    this.handleLinuxSelect = this.handleLinuxSelect.bind(this);
    this.handleWindowsSelect = this.handleWindowsSelect.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
  }

  async generatePassword() {
    await this.setState({
      buttonText: 'Loading...',
      makingPostRequest: true
    });

    const { windowsChosen, hostname, salt, length, passwordKey } = this.state;

    var api_url = `${process.env.GATSBY_API_URL}/generate`

    var system_query_string = '';

    // Use 2017 system for Windows and 2019 for Linux.
    if (windowsChosen) {
      system_query_string = '?system=2017';
    } else {
      system_query_string = '?system=2019'; 
    }
    
    api_url = api_url + system_query_string;

    if (hostname !== '') {
      api_url = api_url + `&hostname=${hostname}`;
    }

    if (salt !== '') {
      api_url = api_url + `&salt=${salt}`;
    }

    if (length !== '') {
      api_url = api_url + `&length=${length}`;
    }

    const request_body = `password=${passwordKey}`;

    // Only add the query string parameters for which the user supplied a value.
    await fetch(api_url,
      { 
        method: 'POST',
        mode: 'cors',
        body: request_body
      }
    )
    .then(async res => {
      const message = await res.json();

      this.setState({
        generatedPassword: true,
        apiResponseStatus: res.status,
        apiResponseMessage: message.message      
      });
    })
    .catch(res => {
      // If there's a network error, assume it's because the user isn't on campus.
      this.setState({
        generatedPassword: true,
        apiResponseStatus: res.status,
        apiResponseMessage: 'Error: API can only be accessed from the campus network.'
      });
    });

    this.updateAlertClass();  
  }

  updateAlertClass() {
    const { apiResponseStatus } = this.state;

    if (apiResponseStatus === 200) {
      var alertClassString = "c-alert c-alert--success"
    } else if (apiResponseStatus === 409) {
      alertClassString = "c-alert c-alert--warning c-alert"
    } else {
      alertClassString = "c-alert c-alert--danger c-alert"
    }

    this.setState({
      alertClassString: alertClassString,
      makingPostRequest: false,
      buttonText: 'Generate password'
    });
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleWindowsSelect(event) {
    this.setState({
      windowsChosen: true,
      saltLabel: 'Salt',
      saltPlaceholder: 'Encryption algorithm salt',
    });
  }

  handleLinuxSelect(event) {
    this.setState({
      windowsChosen: false,
      saltLabel: 'User',
      saltPlaceholder: 'The system user whose password you require (defaults to "root" if left blank)'
    });
  }

  render() {
    return(
      <React.Fragment>
        <br />
          { this.state.generatedPassword &&
            <div class={this.state.alertClassString} role="alert">
              <div class="c-alert__content">
                <pre>{this.state.apiResponseMessage}</pre>
              </div>
            </div>
          }
        <div class="c-form__element">
          <label class="c-form__label" for="a">Hostname</label>
          <input 
            class="c-form__input c-form__input--text" 
            type='text'
            name='hostname'
            value={this.state.hostname}
            onChange={this.handleInputChange}
            placeholder='A fully-qualified hostname (minus the final dot) e.g. foo.york.ac.uk'
          />
        </div>
        <br />

        <div class="c-form__element">
          <label class="c-form__label" for="k">Host's operating system</label>
          <div class="c-form__element-group" id="k">
            <div class="c-form__radio-group">
              <input
                class="c-form__radio"
                type="radio"
                id='windows'
                checked={this.state.windowsChosen}
                onChange={this.handleWindowsSelect}
              />
              <label for="windows" class="c-form__label">Windows</label>
            </div>
            <div class="c-form__radio-group">
              <input
                class="c-form__radio"
                type="radio"
                id='linux'
                checked={!this.state.windowsChosen}
                onChange={this.handleLinuxSelect}
              />
              <label for="linux" class="c-form__label">Linux</label>
            </div>
          </div>
        </div>
        <br />

        <div class="c-form__element">
          <label class="c-form__label" for="a">Length</label>
          <input 
            class="c-form__input c-form__input--text" 
            type="text"
            name="length"
            value={this.state.length}
            onChange={this.handleInputChange}
            placeholder="The generated password's length (defaults to 16 if left blank)"
          />
        </div>
        <br />

        <div class="c-form__element">
          <label class="c-form__label" for="a">{this.state.saltLabel}</label>
          <input 
            class="c-form__input c-form__input--text" 
            type='text'
            name='salt'
            value={this.state.salt}
            onChange={this.handleInputChange}
            placeholder={this.state.saltPlaceholder}
          />
        </div>
        <br />

        <div class="c-form__element">
          <label class="c-form__label" for="a">Password key</label>
          <input 
            class="c-form__input c-form__input--password" 
            type="password"
            name="passwordKey"
            value={this.state.passwordKey}
            onChange={this.handleInputChange}
            placeholder='The secret bit'
          />
        </div>
        <br />

        <div class="o-grid__box o-grid__box--full">
          <button
            class="c-btn c-btn--success c-btn--medium"
            onClick={this.generatePassword}
            disabled={this.state.makingPostRequest}
          >
            {this.state.buttonText}
          </button>
        </div>
      </React.Fragment>);
  }
}

export default Form
